.vital-data-viewer {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

.vital-logo {
    margin-top: 2rem;
    margin-bottom: 2rem;
}