
.app-header {
  background-color: #57193F;
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 10px;
}

.app-header .app-header-middle {
  flex-grow: 2;
}

.app-header .app-header-top-logo {
  padding-left: 10%;
}

.app-header .top-menu {
  padding-right: 10%;
  display: flex;
}

.app-header .top-menu div {
  margin: 10px;
}

.app-header .top-menu a {
  text-decoration: none;
  color: white;
}

.app-header-top-logo img {
  height: 74px;
}

.top-menu {
  display: flex;
  color: white;
  align-items: center;
}

.app-content {
  background-color: #f4f2ee;
  width: 80%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.app-footer {
  background-color: #441832;
  color: white;
}

.app-footer-menu {
  width: 100%;
  font-size: 14px;
  font-weight: 200;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.app-footer-menu a {
  text-decoration: none;
  color: white;
}

.app-footer-menu > div {
  width: 33%;
  padding: 10px;
  text-align: center;
}

.app-footer-bottom {
  background-color: #441832;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  text-align: center;
}

.healthb-primary-btn {
  display: inline-block;
  height: 52px;
  border-radius: 6px;
  background-color: #792D5C;
  padding: 0 24px;
  outline: 0 solid rgba(255,255,255,0);
  border: 1px solid rgba(255,255,255,0);
  color: #ffffff;
  cursor: pointer;
}

.healthb-input-text {
  height: 52px;
  border: 1px solid #E2DBD4;
  padding: 0 16px;
  border-radius: 6px;
  outline: 1px solid rgba(255,255,255,0);
  color: #282B2D;
  width: 90%;
}

.healthb-textarea-text {
  height: 52px;
  border: 1px solid #E2DBD4;
  padding: 16px 16px;
  border-radius: 6px;
  outline: 1px solid rgba(255,255,255,0);
  color: #282B2D;
  width: 90%;
}