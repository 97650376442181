.scanner-viewer {
    padding: 1em;
    border: 0px solid silver;
    border-radius: 2em;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scanner-info-big {
    font-weight: 600;
    font-size: large;
    margin-bottom: 2em;
}

.scanner-info {
    margin-top: 2em;
}

.scanner-link {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.scanner-link a {
    padding: 1em;
    border: 1px solid silver;
    border-radius: 0.8em;
    color: rgb(1, 46, 243);
    font-weight: 600;
}

.scanner-link input {
    margin-left: 2em;
    height:2em;
    width: 2em;
}

.shared-by-number {
    border-top: 1px solid grey;
    padding-top: 20px;
    width: 100%;
    text-align: center;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
}

.shared-by-number span {
    display: block;
    font-weight: 600;
    font-size: large;
    margin-bottom: 1em;
    margin-top: 1em;
}

.shared-by-number input {
    display: block;
    font-size: x-large;
    width: 50%;
    text-align: center;
    min-width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    margin-top: 1em;
}

.shared-by-number button {
    display: block;
    font-size: x-large;
    width: 50%;
    text-align: center;
    min-width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    margin-top: 1em;
}
