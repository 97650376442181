body {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.3rem;
    background-color: #f4f2ee;
}

headers {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-top: 0;
}

accent {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-style: italic;
    /*text-shadow: 0px 0px 1px $grey02;*/
    margin-top: 0;
}

ui {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;   
    font-size: 1.5rem;
    line-height: 1.9rem;
}

caption {
    font-size: 1.3rem;
    line-height: 1.7rem;
}


html {
    font-size: 62.5%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    @include headers;
    font-size: 3.6rem;
    line-height: 5rem;
    margin-bottom: 3rem;
    
    @media only screen and (min-width: $medium) {
        font-size: 4rem;
        line-height: 5.2rem;
        margin-bottom: 4.6rem;
    }
}
  
h2 {
    @include headers;
    font-size: 3.2rem;
    line-height: 3.6rem;
    margin-bottom: 2.8rem;
    
    @media only screen and (min-width: $medium) {
        font-size: 3.6rem;  
        line-height: 4rem;
        margin-bottom: 3.4rem;
    }
}

h3 {
    @include headers;
    font-size: 3rem;
    line-height: 3.8rem;
    margin-bottom: 0.6rem;

    @media only screen and (min-width: $medium) {
        font-size: 3.2rem;
        line-height: 4.2rem;
        margin-bottom: 0.8rem;
    }
}

h4 {
    @include accent;
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 0.4rem;
    
    @media only screen and (min-width: $medium) {
        font-size: 2.6rem;
        line-height: 3.4rem;
        margin-bottom: 0.6rem;
    }
}

h5 {
    @include accent;
    font-size: 2rem;
    line-height: 2.6rem;
    margin-bottom: 1rem;
    
    @media only screen and (min-width: $medium) {
        font-size: 2.2rem;
        line-height: 2.8rem;
        margin-bottom: 1.2rem;
    }
}

h6 {
    @include accent;
    font-size: 1.8rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
    
    @media only screen and (min-width: $medium) {
        font-size: 2rem;
        line-height: 2.6rem;
    }
}

.h7 {
    @include accent;
    font-size: 1.6rem;
    line-height: 2.1rem;
    margin-bottom: 0.6rem;
    
    @media only screen and (min-width: $medium) {
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 0.8rem;
    }
}

p {
    margin-bottom: 3.4rem;

    @media only screen and (min-width: $medium) {
        margin-bottom: 3.8rem;
    }
}

.first {
    margin-bottom: 1.6rem;
}

.last {
    margin-bottom: 0;
}

.larger {
    font-size: 1.7rem;
    line-height: 2.4rem;
}

.smaller {
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0 !important;
}

.ui {
    @include ui;
}

.caption {
    @include caption;
    font-style: italic; 
}

li {
    margin-left: 18px;
    list-style-type: disc;
    margin-bottom: 4px;
}
