.data-viewer {
    display: flex;
}

.data-viewer-patient {
    padding: 30px;
    width: 50%;
}

.data-view-profile {
    display: flex;
    flex-direction: column;
}

.data-viewer-profile div h2 {
    width: 100%;
}

.patient-data-section {
    margin-top: 20px;
}

.patient-data-section-title {
    font-style: italic;
    margin-bottom: 6px;
}

.patient-data-row {
    display: flex;
    background-color: white;
    padding: 6px;
    padding-right: 20px;
    border-radius: 6px;
    margin-bottom: 6px;
}

.patient-data-value {
    padding-left: 10px;
    flex-grow: 10;
}

.patient-data-date {
    flex-grow: 0;
}

.copy-button {
    background-color: brown;
}

.data-view-messages {
    width: 50%;
    padding-top: 30px;
}

.message-to-patient {
    padding-top: 2em;
}

.message-type {
    padding: 0.5em;
}

.message-type select {
    font-size: large;
    padding: 0.5em;
}

.message-note-type {
    padding: 0.5em;
}

.message-note-type select {
    font-size: large;
    padding: 0.5em;
}

.message-content {
    padding: 0.5em;
}

.message-content textarea {
    min-height: 10em;
    min-width: 30em;
}

.message-send-button {
    padding: 0.5em;
}

.message-send-button button {
    font-size: 16px;
}

.healthb-primary-btn {

    font-size: 16px;
}

.note-view {
    padding-bottom: 1rem;
    border-bottom: 1px solid silver;
}

.note-view .note-title {
    font-weight: 600;
    margin-bottom: 1rem;
}

@media (max-width: 700px) {
    body {
        margin: 0;
    }

    .data-viewer {
        flex-direction: column;
    }

    .data-viewer-patient {
        padding: 0;
        width: 100%;
    }

    .data-view-messages {
        width: 100%;
    }

    .message-content textarea {
        min-width: 100%;
    }

    .healthb-textarea-text {
        width: 100%;
    }

    .app-header {
        display: flex;
        flex-direction: column;
    }

    .app-header .app-header-top-logo {
        text-align: center;
        padding: 0;
        margin-top: 10px;
    }

    .app-header .top-menu {
        flex-direction: row-reverse;
    }

    .app-footer-menu {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .app-footer-menu>div {
        width: 90%;
    }

    .message-content {
        padding: 0;
        margin-top: 10px;
    }

    .message-content textarea {
        min-width: 90%;
        width: 90%;
    }

    .healthb-primary-btn {
        width: 100%;
        font-size: 16px;
    }

    .message-send-button {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0;
    }

    .message-send-button button {
        font-size: 16px;
    }
}
